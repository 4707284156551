import createReducer from '../createReducer';
import { USER_INFO } from './actions';
const initialState = {
  userInfo: {}
};

export default createReducer(initialState, {
  [USER_INFO]: (state, action) => ({
    ...state,
    userInfo: action.userInfo
  })
});
