import thunk from 'redux-thunk';
import { settings, menu, auth, authUser, matches, userInfo, games, bets } from './reducers';
import { init } from '@rematch/core';
import createLoadingPlugin from '@rematch/loading';
import selectPlugin from '@rematch/select';
import * as models from '../models';

const middlewares = [thunk];

export function configureStore() {
  const loading = createLoadingPlugin({});

  const store = init({
    redux: {
      reducers: {
        settings,
        menu,
        auth,
        authUser,
        matches,
        userInfo,
        games,
        bets
      },
      middlewares
    },
    models,
    plugins: [loading, selectPlugin()]
  });

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
