import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import auth from './auth/reducers';
import authUser from './user/reducers';
import matches from '../v2/redux/matches/reducer';
import userInfo from '../v2/redux/userInfo/reducer';
import games from '../v2/redux/games/reducer';
import bets from '../v2/redux/bets/reducer';

const reducers = combineReducers({
  menu,
  settings,
  auth,
  authUser,
  matches,
  userInfo,
  games,
  bets
});

export default reducers;
export { menu, settings, auth, authUser, matches, userInfo, games, bets };
